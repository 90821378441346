import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NotFound.css';
import Layout from '../components/Layout';

// 404 Not Found page
const NotFound = (): JSX.Element => {
  const timestamp = new Date().toLocaleString();
  const requestedURL = window.location.href;

  return (
    <Layout>
      <div className="not-found-container">
        <h1 className="not-found-title">404 - Page Not Found</h1>
        <p className="not-found-message">
          Sorry, the page you are looking for does not exist or has been moved.
        </p>
        <p className="not-found-info">
          Timestamp: <span className="info-bold">{timestamp}</span>
        </p>
        <p className="not-found-info">
          Requested URL: <span className="info-bold">{requestedURL}</span>
        </p>
        <Link to="/home" className="not-found-link">
          Back to Home
        </Link>
      </div>
    </Layout>
  );
};

export default NotFound;
