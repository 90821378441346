import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import AllRoutes from './Routes';
import { AuthProvider } from './context/AuthContext';

// Define the application router with authentication context
const router = createBrowserRouter(
  [
    {
      path: '*',
      element: (
        <AuthProvider>
          <AllRoutes />
        </AuthProvider>
      ),
    },
  ],
  {
    future: {
      // Enable experimental React Router v7 behavior
      // eslint-disable-next-line camelcase
      v7_relativeSplatPath: true,
    },
  },
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// Render the application
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

reportWebVitals(); // Collect and report web performance metrics
