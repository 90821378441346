import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import useDashboardData from '../hooks/useDashboardData';
import supabaseService from '../services/SupabaseService';

// Pages initialization
import { HeaderAuth } from './HeaderAuth';

// Stylesheet initialization
import '../styles/App.css';
import '../styles/Header.css';
import { useAuth } from '../context/AuthContext';

// Constant initialization
const logoPath = '/assets/icon.png';

// Private functions
function Logo() {
  return <img src={logoPath} className="App-logo" alt="logo" />;
}

function Navigation() {
  const [expanded, setExpanded] = useState(false);
  const auth = useAuth() ?? {};
  const state = auth.state ?? {};
  const isInitialized = auth.isInitialized ?? false;
  const { session } = useDashboardData();

  if (!isInitialized) {
    return null; // Do not render anything until auth state is initialized
  }

  // Safely extract user email
  const rawEmail = session?.user?.email || '';
  const hasAtSymbol = rawEmail.includes('@');

  let isEmailAuthorized = false;
  let domainPart = '';

  // Check email domain only if '@' exists
  if (hasAtSymbol) {
    isEmailAuthorized = supabaseService.checkEmailDomain(rawEmail);
    domainPart = rawEmail.split('@')[1]?.toLowerCase() || '';
  }

  // Validate specific domains
  const isStatusDomain =
    domainPart === 'bristol.ac.uk' ||
    domainPart === 'edinburghtrams.com' ||
    domainPart === 'polychord.co.uk';

  return (
    <Navbar
      expand="lg"
      bg="default"
      data-bs-theme="dark"
      expanded={expanded}
      onToggle={(isExpanded) => setExpanded(isExpanded)}
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          aria-label="Toggle navigation"
          aria-expanded={expanded}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <Nav.Link
              as={Link}
              to="/home"
              className={`py-3 ${!expanded ? 'margin-right' : ''}`}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={session ? '/dashboard' : '/login'}
              className={`py-3 ${!expanded ? 'margin-right' : ''}`}
            >
              Dashboard
            </Nav.Link>
            {isEmailAuthorized && isStatusDomain ? (
              // Only admins and Edinburgh Trams get access to the status page
              <Nav.Link
                as={Link}
                to="/status"
                className={`py-3 ${!expanded ? 'margin-right' : ''}`}
              >
                Status
              </Nav.Link>
            ) : (
              <Nav.Link
                href="https://polychord.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className={`py-3 ${!expanded ? 'margin-right' : ''}`}
              >
                About
              </Nav.Link>
            )}
            <Nav.Link
              href="https://polychord.co.uk/index.html#contact"
              target="_blank"
              rel="noopener noreferrer"
              className={`py-3 ${!expanded ? 'margin-right' : ''}`}
            >
              Contact Us
            </Nav.Link>
            <HeaderAuth state={state} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

// Public Header Function
function Header() {
  return (
    <header>
      <Navigation />
    </header>
  );
}

export default Header;
