import { createClient, SupabaseClient } from '@supabase/supabase-js';

// Supabase credentials from environment variables
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || '';
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY || '';

const allowedDomains = [
  'bristol.ac.uk',
  'edinburghtrams.com',
  'polychord.co.uk',
  'westmidlandsmetro.com',
];

// Supabase service class for authentication and data fetching
class SupabaseService {
  private client: SupabaseClient;
  private auth: SupabaseClient['auth'];
  private pointOfInterests: { [key: string]: number | string }[];

  constructor() {
    if (SUPABASE_URL === '' || SUPABASE_KEY === '')
      throw new Error(
        'Please setup SUPABASE_URL and SUPABASE_KEY in your .env file',
      );
    this.client = createClient(SUPABASE_URL, SUPABASE_KEY);
    this.auth = this.client.auth;
    this.pointOfInterests = [];
  }

  // Generate a random secure password
  generatePassword(length: number): string {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()-_=+[]{}|;:,.<>?';

    // Ensure each type of character appears at least once
    const allChars = lowercase + uppercase + numbers + specialChars;
    let password = [
      lowercase[Math.floor(Math.random() * lowercase.length)],
      uppercase[Math.floor(Math.random() * uppercase.length)],
      numbers[Math.floor(Math.random() * numbers.length)],
      specialChars[Math.floor(Math.random() * specialChars.length)],
    ];

    // Fill the remaining length with random characters from all sets
    const remainingLength = length - password.length;
    const randomBytes = new Uint8Array(remainingLength);
    crypto.getRandomValues(randomBytes);

    for (let i = 0; i < remainingLength; i++) {
      password.push(allChars[randomBytes[i] % allChars.length]);
    }

    // Shuffle the password to avoid predictable patterns
    password = password.sort(() => 0.5 - Math.random());

    return password.join('');
  }

  // Send a magic link for authentication
  async sendMagicLink(email: string): Promise<void> {
    try {
      if (!this.checkEmailDomain(email)) {
        return;
      }

      const { error } = await this.client.auth.signInWithOtp({ email });

      if (error) {
        alert('Please enter a valid email address.');
        throw error;
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error sending magic link:', error.message);
      } else {
        console.error('Unexpected error sending magic link:', error);
      }
    }
  }

  // Verify the magic link
  async verifyMagicLink(accessToken: string): Promise<{ error?: Error }> {
    try {
      const { error } = await this.getAuth().verifyOtp({
        /* eslint-disable-next-line camelcase */
        token_hash: accessToken,
        type: 'email',
      });

      if (error) {
        throw error;
      }

      return {}; // Return empty object if successful
    } catch (error) {
      return { error: error as Error };
    }
  }

  // Get Supabase authentication instance
  public getAuth(): SupabaseClient['auth'] {
    return this.auth;
  }

  // Get Supabase client instance
  public getClient(): SupabaseClient {
    return this.client;
  }

  // Fetch and return points of interest (POI)
  public async getPointOfInterests(): Promise<
    { [key: string]: number | string }[]
  > {
    if (
      this.pointOfInterests.length === 0 &&
      (await this.getAuth().getSession()).data.session
    ) {
      const result = await this.fetchPointsOfInterest();

      this.pointOfInterests = result?.pointsOfInterest || [];
    }
    return this.pointOfInterests;
  }

  // Fetch POI data from Supabase
  private fetchPointsOfInterest = async () => {
    const { data, error } = await this.client
      .from('points-of-interest')
      .select()
      .order('Date POI First appeared', { ascending: false })
      .limit(100);

    if (error) {
      console.error('Error fetching points of interest:', error.message);
    } else {
      return { pointsOfInterest: data };
    }
  };

  // Logout user and clear session
  async logout(): Promise<void> {
    try {
      const { error } = await this.getAuth().signOut();
      if (error) {
        throw error;
      }
      this.pointOfInterests = [];
      alert('Logged Out Successfully!');
      console.log('Logged Out Successfully!');
      window.location.href = '/';
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error logging out:', error.message);
      } else {
        console.error('Unexpected error logging out:', error);
      }
    }
  }

  // Check if email belongs to an allowed domain
  checkEmailDomain(email: string): boolean {
    const emailDomain = email.split('@')[1];
    return allowedDomains.some((domain) => emailDomain.endsWith(domain));
  }

  // Register user with a randomly generated password
  async registerUser(email: string) {
    if (!this.checkEmailDomain(email)) {
      return {
        error: new Error(
          'It looks like you are ineligible for this service. If you believe this is a mistake, please contact us',
        ),
      };
    }

    try {
      //eslint-disable-next-line
      const { data, error } = await this.getAuth().signUp({
        email,
        password: this.generatePassword(24),
      });

      if (error) {
        console.error(error);
        return error;
      }
      return null;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error sending magic link:', error.message);
      } else {
        console.error('Unexpected error sending magic link:', error);
      }
    }
  }
}

const supabaseServiceInstance = new SupabaseService();
export default supabaseServiceInstance;
