import { useState, useEffect, useRef } from 'react';
import SupabaseService from '../services/SupabaseService';
import { Session } from '@supabase/supabase-js';

// Hook to manage dashboard data (user session + points of interest)
const useDashboardData = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [pointsOfInterest, setPointsOfInterest] = useState<
    { [key: string]: number | string }[]
  >([]);
  const initialFetchRef = useRef(true); // Prevents multiple fetch calls on mount

  useEffect(() => {
    const fetchSession = async () => {
      const {
        data: { session },
      } = await SupabaseService.getAuth().getSession();
      setSession(session);

      // Listen for authentication state changes
      SupabaseService.getAuth().onAuthStateChange((_event, session) => {
        setSession(session);
      });
    };

    const fetchPointsOfInterest = async () => {
      const points = await SupabaseService.getPointOfInterests();
      setPointsOfInterest(points);
    };

    if (initialFetchRef.current) {
      fetchSession();
      fetchPointsOfInterest();
      initialFetchRef.current = false;
    }
  }, [pointsOfInterest]);

  return { session, pointsOfInterest };
};

export default useDashboardData;
