import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SupabaseService from '../services/SupabaseService';

// Handles authentication via magic link
const Authenticate: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleMagicLink = async () => {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('token');
      const type = url.searchParams.get('type');

      if (token && type) {
        const result = await SupabaseService.verifyMagicLink(token);
        if (result?.error) {
          console.error('Error verifying magic link:', result.error.message);
          setError('Error verifying magic link. Please try again.');
        } else {
          navigate('/dashboard'); // Redirect to dashboard on success
        }
      } else {
        setError('Invalid or expired magic link.');
      }
    };

    handleMagicLink();
  }, [navigate]);

  return (
    <div>
      {error ? <div>Error: {error}</div> : <div>Verifying magic link...</div>}
    </div>
  );
};

export default Authenticate;
