import React, { useState } from 'react';
import Layout from '../components/Layout';
import SupabaseService from '../services/SupabaseService';
import '../styles/Login.css';

// Login page with magic link authentication
const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  // Handle login form submission
  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setMessage(null);

    if (!email) {
      setLoading(false);
      return;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await SupabaseService.sendMagicLink(email);
      setMessage('');
      setIsError(false);
    } catch {
      setMessage('An error occurred. Please try again.');
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="login">
        <div className="login-content">
          <div className="login-box">
            <h2>Sign in to your account</h2>
            <p>Enter your email to receive a magic link</p>
            <form onSubmit={handleLogin}>
              <label htmlFor="email" className="field-label">
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="mail@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-label="Email address"
              />
              <button type="submit" disabled={loading}>
                {loading ? (
                  <>
                    <svg
                      className="spinner"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <circle
                        className="spinner-circle"
                        cx="12"
                        cy="12"
                        r="10"
                        strokeDasharray="31.4 31.4"
                        strokeDashoffset="0"
                        strokeLinecap="round"
                      />
                    </svg>
                    Sending...
                  </>
                ) : (
                  <>
                    <svg
                      className="icon"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8m-2 10H5a2 2 0 01-2-2V8a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2z"
                      />
                    </svg>
                    Send Magic Link
                  </>
                )}
              </button>
            </form>
            {message !== null && (
              <div
                className={`bubble ${isError ? 'bubble-error' : 'bubble-success'}`}
              >
                {isError ? (
                  message
                ) : (
                  <>
                    If you have an account with us, you will receive a login
                    link.
                    <br />
                    Please check your inbox.
                  </>
                )}
              </div>
            )}
            <p className="register-text">
              Don’t have an account? <a href="/register">Register here</a>
            </p>
          </div>
        </div>
        {/* Background animation */}
        <video
          autoPlay
          muted
          loop
          disablePictureInPicture
          playsInline
          poster=""
          className="login-background"
        >
          <source
            src="/assets/digital-animation.mp4"
            type="video/mp4; codecs=avc1.4D401E"
          />
        </video>
      </div>
    </Layout>
  );
};

export default Login;
