import { onCLS, onINP, onFCP, onLCP, onTTFB, Metric } from 'web-vitals';

// Reports web vitals metrics if a callback is provided
const reportWebVitals = (onPerfEntry?: (metric: Metric) => void) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    onCLS(onPerfEntry); // Cumulative Layout Shift
    onINP(onPerfEntry); // Interaction to Next Paint
    onFCP(onPerfEntry); // First Contentful Paint
    onLCP(onPerfEntry); // Largest Contentful Paint
    onTTFB(onPerfEntry); // Time to First Byte
  }
};

export default reportWebVitals;
