// Defines structure for a single reading from a device
interface Reading {
  device_name: string;
  value: string;
  latitude: number | null;
  longitude: number | null;
  timestamp: string;
  precise_timestamp: string;
  channel_name: string;
  unit_name: string;
}

// Defines structure for a device and its readings
interface DeviceData {
  id: number;
  name: string;
  status: string;
  group_id: number;
  readings: Reading[];
}

// Defines structure for paginated device data within a group
interface GroupDevicesData {
  devices: DeviceData[];
  total: number;
  page: number;
  perPage: number;
}

// API service for fetching device-related data
class DeviceIdAPI {
  private readonly baseUrl: string;
  private readonly headers: HeadersInit;

  constructor() {
    this.baseUrl = process.env.REACT_APP_DEVICE_API_URL || '';
    this.headers = {
      Accept: 'application/json',
      'X-API-KEY': process.env.REACT_APP_DEVICE_API_KEY || '',
      Origin: window.location.origin,
      Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
    };
  }

  // Fetch device details by its ID
  async getDeviceById(deviceId: number): Promise<DeviceData> {
    try {
      const response = await fetch(`${this.baseUrl}/${deviceId}`, {
        method: 'GET',
        headers: this.headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching device data:', error);
      throw error;
    }
  }

  // Fetch all devices within a group, supports JSON or XML format
  async getDevicesByGroupId(
    format: 'json' | 'xml' = 'json',
  ): Promise<GroupDevicesData> {
    try {
      const headers = {
        ...this.headers,
        Accept: format === 'json' ? 'application/json' : 'application/xml',
      };

      const response = await fetch(`${this.baseUrl}/group-devices`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching device data:', error);
      throw error;
    }
  }

  // Fetch device data for a specific date range
  async getDeviceDataByDateRange(
    deviceId: number,
    startDate: Date,
    endDate: Date,
  ): Promise<DeviceData> {
    try {
      /* eslint-disable camelcase */
      const queryParams = new URLSearchParams({
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      });
      /* eslint-enable camelcase */

      const response = await fetch(
        `${this.baseUrl}/${deviceId}?${queryParams}`,
        {
          method: 'GET',
          headers: this.headers,
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching device data with date range:', error);
      throw error;
    }
  }
}

const deviceIdAPI = new DeviceIdAPI();
export default deviceIdAPI;
