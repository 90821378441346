import React, { Routes, Route, Navigate } from 'react-router-dom';

// Import all pages here
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import Authenticate from './pages/Authenticate';
import NotFound from './pages/NotFound';
import Status from './pages/Status';

// Defines all application routes
const AllRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/status" element={<Status />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/authenticate" Component={Authenticate} />
      <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404s */}
    </Routes>
  );
};
export default AllRoutes;
