import React, { useState, useRef, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import Layout from '../components/Layout';
import useDashboardData from '../hooks/useDashboardData';
import Map from '../components/Map';
import '../styles/Dashboard.css';
import { MapHandleZoom } from '../components/Map';
import supabaseServiceInstance from '../services/SupabaseService';
import { saveAs } from 'file-saver';
import SortButton, { SortDirection } from '../components/SortButton';

export type PointOfInterest = {
  id: string;
  Latitude: number;
  Longitude: number;
  createdAt: string | number;
  rank: number;
  stdError: number;
  dateFirstAppeared: Date;
  company: string;
  notes: string;
  status: string;
  activity: string;
  assignedTo: string;
  dueBy: Date;
};

const Dashboard: React.FC = () => {
  // Retrieve session data and POI data using useDashboardData()
  const supabase = supabaseServiceInstance.getClient();
  const { session, pointsOfInterest } = useDashboardData();
  const email = session?.user?.email;
  const mapRef = useRef<MapHandleZoom>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null,
  );
  const [fadeClass, setFadeClass] = useState<string>('fade-in');

  // Format points of interest data, excluding Supabase's internal fields `createdAt` and `ID`
  const [mapPoints, setMapPoints] = useState<PointOfInterest[]>([]);
  const [tablePoints, setTablePoints] = useState<PointOfInterest[]>([]);

  // Effect to format POI data when `pointsOfInterest` updates
  useEffect(() => {
    const formatted = pointsOfInterest
      .map((poi) => ({
        id: poi.id.toString(),
        Latitude: Number(poi.Latitude),
        Longitude: Number(poi.Longitude),
        createdAt: poi.created_at,
        rank: Number(poi.Rank),
        stdError: Number(poi['Standard Deviation of Error (m)']),
        dateFirstAppeared: new Date(poi['Date POI First appeared']),
        company: String(poi['Company']),
        notes: String(poi.Notes),
        status: String(poi.Status),
        activity: String(poi.Activity),
        assignedTo: String(poi['Assigned To']),
        dueBy: new Date(poi['Due By']),
      }))
      .sort((a, b) => a.rank - b.rank);

    setMapPoints(formatted);
    setTablePoints(formatted);
  }, [pointsOfInterest]);

  const minRank = Math.min(...mapPoints.map((poi) => poi.rank));
  const maxRank = Math.max(...mapPoints.map((poi) => poi.rank));

  // Sorting state
  const [sortConfig, setSortConfig] = useState<{
    column: string;
    direction: SortDirection;
  }>({
    column: 'rank',
    direction: 'asc',
  });

  // Handle sorting when a column header is clicked
  const handleSort = (column: string) => {
    setSortConfig((prevSort) => ({
      column,
      direction:
        prevSort.column === column
          ? prevSort.direction === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc',
    }));

    setTablePoints((prevPoints) => {
      const sortedPoints = [...prevPoints].sort((a, b) => {
        const aValue = a[column as keyof PointOfInterest];
        const bValue = b[column as keyof PointOfInterest];

        if (aValue === bValue) return 0;

        if (sortConfig.direction === 'asc') {
          return aValue < bValue ? -1 : 1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
      return sortedPoints;
    });
  };

  // Component for rendering POI table rows
  function Points({ currentPoints }: { currentPoints: PointOfInterest[] }) {
    return (
      <>
        {currentPoints &&
          currentPoints.map((point) => (
            <tr key={point.id} className="flex-row">
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={'../assets/location_pin.png'}
                    alt={point.rank.toString()}
                    className="location-pin"
                    onClick={() => {
                      mapRef.current?.zoomToLocation(
                        point.Latitude,
                        point.Longitude,
                        point,
                      );
                      setTimeout(() => {
                        mapRef.current?.toggleTab('details');
                      }, 1000);
                    }}
                  />
                  <span>{point.rank}</span>
                </div>
              </td>
              <>
                <td>{point.activity === 'null' ? 'N/A' : point.activity}</td>
                <td>
                  {point.assignedTo === 'null' ? 'N/A' : point.assignedTo}
                </td>
              </>
              <td>
                {point.dueBy.toLocaleDateString('en-gb') === 'null'
                  ? 'N/A'
                  : point.dueBy.toLocaleDateString('en-gb')}
              </td>
              <td>
                {point.dateFirstAppeared.toLocaleDateString('en-gb') === 'null'
                  ? 'N/A'
                  : point.dateFirstAppeared.toLocaleDateString('en-gb')}
              </td>
              <td>
                <select
                  className="status"
                  value={point.status}
                  onChange={(e) => updateStatus(e.target.value, point.id)}
                  style={{
                    backgroundColor:
                      point.status === 'Not Started'
                        ? '#ffcccc'
                        : point.status === 'In Progress'
                          ? '#ffe680'
                          : '#ccffcc',
                    color:
                      point.status === 'Not Started'
                        ? '#b30000'
                        : point.status === 'In Progress'
                          ? '#996600'
                          : '#006600',
                  }}
                >
                  <option value={'Not Started'}>Not Started</option>
                  <option value={'In Progress'}>In Progress</option>
                  <option value={'Completed'}>Completed</option>
                </select>
              </td>
              <td>
                <button
                  className="notes-toggle"
                  onClick={() => {
                    mapRef.current?.zoomToLocation(
                      point.Latitude,
                      point.Longitude,
                      point,
                    );
                    setTimeout(() => {
                      mapRef.current?.toggleTab('notes');
                    }, 1000);
                  }}
                >
                  View Notes
                </button>
              </td>
            </tr>
          ))}
      </>
    );
  }

  // Component for paginating and rendering POI table rows
  function PaginatedItems({
    itemsPerPage,
    itemOffset,
    setItemOffset,
  }: {
    itemsPerPage: number;
    itemOffset: number;
    setItemOffset: React.Dispatch<React.SetStateAction<number>>;
  }) {
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = tablePoints.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(tablePoints.length / itemsPerPage);

    const currentPage = Math.floor(itemOffset / itemsPerPage);
    const handlePageClick = (event: { selected: number }) => {
      const newOffset = (event.selected * itemsPerPage) % tablePoints.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Points currentPoints={currentItems} />
        <tr>
          <td colSpan={7}>
            <div className="dashboard-toggle">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName="react-paginate"
                activeClassName="selected"
                forcePage={currentPage}
              />
              <button className="dashboard-download" onClick={downloadCSV}>
                <b>Download CSV ⤓</b>
              </button>
            </div>
          </td>
        </tr>
      </>
    );
  }

  const downloadCSV = () => {
    if (!tablePoints.length) {
      console.error('No points of interest to download.');
      return;
    }

    const csvHeaders = [
      'ID',
      'Latitude',
      'Longitude',
      'Rank',
      'Radial Uncertainty (m)',
      'Date First Appeared',
      'Due By',
      'Company',
      'Activity',
      'Assigned To',
      'Status',
      'Notes',
    ].join(',');

    const csvRows = tablePoints.map((point) => {
      // Parse notes to get the text content
      let notesContent = '';
      try {
        const parsedNotes = JSON.parse(
          point.notes === 'null' ? '[]' : point.notes,
        );
        notesContent = parsedNotes
          .map(
            (note: { text: string; author: string; timestamp: string }) =>
              `${note.text} (by ${note.author} on ${new Date(note.timestamp).toLocaleDateString()})`,
          )
          .join(' | ');
      } catch {
        notesContent = point.notes || '';
      }

      // Escape any commas in text fields and wrap in quotes if needed
      const escapeCsvField = (field: string) => {
        const stringField = String(field);
        return stringField.includes(',') ||
          stringField.includes('"') ||
          stringField.includes('\n')
          ? `"${stringField.replace(/"/g, '""')}"`
          : stringField;
      };

      return [
        point.id,
        point.Latitude,
        point.Longitude,
        point.rank,
        point.stdError.toFixed(2),
        point.dateFirstAppeared.toLocaleDateString('en-gb'),
        point.dueBy.toLocaleDateString('en-gb'),
        escapeCsvField(point.company),
        escapeCsvField(point.activity),
        escapeCsvField(point.assignedTo),
        point.status,
        escapeCsvField(notesContent),
      ].join(',');
    });

    const csvContent = [csvHeaders, ...csvRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(
      blob,
      `points_of_interest_${new Date().toISOString().split('T')[0]}.csv`,
    );
  };
  const [itemOffset, setItemOffset] = useState(0);
  // Function for updating the status of the POI
  async function updateStatus(newStatus: string, id: string) {
    try {
      const { error } = await supabase
        .from('points-of-interest')
        .update({ Status: newStatus })
        .eq('id', id);

      if (error) {
        console.error('Error updating status:', error.message);
        return;
      }
      setTablePoints((prevPoints) =>
        prevPoints.map((poi) =>
          poi.id === id ? { ...poi, status: newStatus } : poi,
        ),
      );
      setItemOffset((prevOffset) => prevOffset);
      setConfirmationMessage('Status updated successfully!');
      setFadeClass('fade-in');

      // Hide confirmation message after 3 seconds
      setTimeout(() => {
        setFadeClass('fade-out');
      }, 3000);
      console.log('Status updated successfully:', newStatus);
    } catch (err) {
      console.error('Error in updateStatus:', err);
    }
  }

  // Render the dashboard
  return (
    <Layout>
      {email && (
        <h4 className="Greeting">
          <strong>Hello, {email}</strong>
        </h4>
      )}
      {(email && (
        <div className="Dashboard-Instructions">
          <h4 className="GreetingParagraph">
            Maps and data show track Points of Interest (POIs) i.e. detected
            issues that require inspection. The smaller the ranking number, the
            greater the severity of the issue/POI. Since GNSS locational
            information is inherently imperfect, we include the radial
            uncertainty for each POI, which describes the radial/circular
            distance around the POI marker that the associated issue could be
            located in reality.
          </h4>
          <h5 className="DashboardInstructions">
            Click
            <img
              src={'../assets/location_pin.png'}
              className="location_pin"
              alt="location_pin"
            />
            on the dashboard to view a point
          </h5>
        </div>
      )) || <h6>Please Log In to Access the Dashboard</h6>}

      {session && (
        <>
          <div className="map-parent-container">
            <div className="map" ref={mapContainerRef}>
              <Map
                ref={mapRef}
                pointsOfInterest={mapPoints}
                minRank={minRank}
                maxRank={maxRank}
                email={session?.user?.email || ''}
              />
            </div>
          </div>
          {confirmationMessage && (
            <div className={`confirmation-message ${fadeClass}`}>
              {confirmationMessage}
            </div>
          )}
          <div className="container">
            <div className="dataView">
              <table>
                <thead>
                  <tr>
                    <th>
                      <strong>
                        <SortButton
                          label="Rank"
                          column="rank"
                          currentSort={sortConfig}
                          onSort={handleSort}
                        />
                      </strong>
                    </th>

                    <>
                      <th>
                        <strong>
                          <SortButton
                            label="Activity"
                            column="activity"
                            currentSort={sortConfig}
                            onSort={handleSort}
                          />
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <SortButton
                            label="Assigned to"
                            column="assignedTo"
                            currentSort={sortConfig}
                            onSort={handleSort}
                          />
                        </strong>
                      </th>
                    </>
                    <th>
                      <strong>
                        <SortButton
                          label="Due by"
                          column="dueBy"
                          currentSort={sortConfig}
                          onSort={handleSort}
                        />
                      </strong>
                    </th>
                    <th>
                      <strong>
                        <SortButton
                          label="Date First Appeared"
                          column="dateFirstAppeared"
                          currentSort={sortConfig}
                          onSort={handleSort}
                        />
                      </strong>
                    </th>
                    <th>
                      <strong>
                        <SortButton
                          label="Status"
                          column="status"
                          currentSort={sortConfig}
                          onSort={handleSort}
                        />
                      </strong>
                    </th>
                    <th>
                      <strong>Notes</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <PaginatedItems
                    itemsPerPage={10}
                    itemOffset={itemOffset}
                    setItemOffset={setItemOffset}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Dashboard;
