import React from 'react';
import '../styles/SortButton.css';

// Defines possible sorting directions
export type SortDirection = 'asc' | 'desc' | 'none';

// Props for the SortButton component
interface SortButtonProps {
  label: string;
  column: string;
  currentSort: { column: string; direction: SortDirection };
  onSort: (column: string) => void;
}

const SortButton: React.FC<SortButtonProps> = ({
  label,
  column,
  currentSort,
  onSort,
}) => {
  // Determine the sorting icon based on the current sort state
  const getSortIcon = () => {
    if (currentSort.column !== column) return '↕';
    return currentSort.direction === 'asc' ? '↑' : '↓';
  };

  return (
    <div className="th-content">
      <span>{label}</span>
      <button
        className="sort-button"
        onClick={() => onSort(column)}
        title={`Sort by ${label}`}
      >
        {getSortIcon()}
      </button>
    </div>
  );
};

export default SortButton;
