import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

// Component to structure the layout with a header, footer, and dynamic content
interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Container fluid className="d-flex flex-column min-vh-100 p-0 m-0 App">
      {/* Header section */}
      <Row className="m-0">
        <Col className="p-0">
          <Header />
        </Col>
      </Row>

      {/* Main content section */}
      <Row className="flex-grow-1 m-3">{children}</Row>

      {/* Footer section */}
      <Row className="m-0">
        <Col className="p-0">
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
