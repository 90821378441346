import React from 'react';

// Props for the user-plus icon component
interface IconUserPlusProps {
  className?: string;
  color?: string;
  size?: number;
}

// User-plus icon component (SVG)
const IconUserPlus: React.FC<IconUserPlusProps> = ({
  className = '',
  color = '#F5F5F5',
  size = 16,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-label="User icon"
    >
      <g id="User plus" clipPath="url(#clip0_428_74)">
        <path
          id="Icon"
          d="M10.6667 14V12.6667C10.6667 11.9594 10.3857 11.2811 9.88564 10.781C9.38554 10.281 8.70726 10 8.00002 10H3.33335C2.62611 10 1.94783 10.281 1.44774 10.781C0.947639 11.2811 0.666687 11.9594 0.666687 12.6667V14M13.3334 5.33333V9.33333M15.3334 7.33333H11.3334M8.33335 4.66667C8.33335 6.13943 7.13945 7.33333 5.66669 7.33333C4.19393 7.33333 3.00002 6.13943 3.00002 4.66667C3.00002 3.19391 4.19393 2 5.66669 2C7.13945 2 8.33335 3.19391 8.33335 4.66667Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs className="defs">
        <clipPath id="clip0_428_74">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconUserPlus;
