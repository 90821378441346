import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import SupabaseService from '../services/SupabaseService';

// Auth state types
type AuthState = 'logged-out' | 'logged-in';

interface AuthContextProps {
  state: AuthState; // Current authentication state
  isInitialized: boolean; // Flag to track if auth check is completed
}

// Create auth context
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Provides authentication context to the app
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<AuthState>('logged-out');
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Check for an existing session on mount
    const fetchSession = async () => {
      const {
        data: { session },
      } = await SupabaseService.getAuth().getSession();
      setState(session ? 'logged-in' : 'logged-out');
      setIsInitialized(true);
    };

    fetchSession();

    // Listen for auth state changes
    const { data: authListener } = SupabaseService.getAuth().onAuthStateChange(
      (_event, session) => {
        setState(session ? 'logged-in' : 'logged-out');
      },
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ state, isInitialized }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
