import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import Layout from '../components/Layout';
import useDashboardData from '../hooks/useDashboardData';
import NotFound from './NotFound';
import '../styles/Status.css';
import deviceIdAPI from '../services/DeviceIdAPI';
import { LineChart } from '@mui/x-charts/LineChart';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // so the x-axis can be time-based
import { Doughnut, Bar } from 'react-chartjs-2';

// Registering chart components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
);

// Interface for a single reading from a device
interface Reading {
  device_name: string;
  value: string;
  latitude: number | null;
  longitude: number | null;
  timestamp: string;
  precise_timestamp: string;
  channel_name: string;
  unit_name: string;
}

// Interface for device data and its readings
interface DeviceData {
  id: number;
  name: string;
  status: string;
  group_id: number;
  readings: Reading[];
}

// Interface for paginated device data within a group
interface Sensor {
  number: number;
  id: number;
  name: string;
  status: string;
  timeStamp: string;
  value: number;
  unit: string;
  channel: string;
}

// Interface for group devices
interface GroupDevice {
  id: number;
  name: string;
  status: string;
  group_id: number;
}

// Status page to fetch device data
const Status = () => {
  const { session } = useDashboardData();
  const email = session?.user?.email;
  const allowedDomains = [
    '@polychord.co.uk',
    '@bristol.ac.uk',
    '@edinburghtrams.com',
  ]; // Only allowed personnel can access this page
  const isAllowedDomain = allowedDomains.some((domain) =>
    email?.endsWith(domain),
  );

  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [groupDevices, setGroupDevices] = useState<GroupDevice[]>([]);
  const [deviceId, setDeviceId] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoadingDevice, setIsLoadingDevice] = useState(false);
  const [isLoadingGroup, setIsLoadingGroup] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState('ACC_RMS_X');
  // eslint-disable-next-line
  const [isAutoLoading, setIsAutoLoading] = useState(false); //used to update the table without turning the fetch group devices button into a spinner
  const [deviceError, setDeviceError] = useState('');
  const [view, setView] = useState<'group' | 'device' | null>(null);
  // eslint-disable-next-line
  const [groupDevicesFetched, setGroupDevicesFetched] = useState(false); //used to load top table without loading bottom table
  const [lastUpdated, setLastUpdated] = useState<string>('');
  const detailsRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line
  const [width, setWidth] = useState(window.innerWidth);

  const [sensorOffset, setSensorOffset] = useState(0);
  const [groupOffset, setGroupOffset] = useState(0);

  const fetchGroupDevices = useCallback(async (isAutoFetch = false) => {
    if (isAutoFetch) {
      setIsAutoLoading(true);
    } else {
      setIsLoadingGroup(true);
    }
    try {
      const data = (await deviceIdAPI.getDevicesByGroupId(
        'json',
      )) as unknown as GroupDevice[];
      setGroupDevices(data);
      setGroupOffset(0);
      setLastUpdated(new Date().toLocaleString());
    } catch (error) {
      console.error('Error fetching group devices:', error);
      setGroupDevices([]);
    } finally {
      if (isAutoFetch) {
        setIsAutoLoading(false);
      } else {
        setIsLoadingGroup(false);
      }
    }
  }, []);

  // fetch group devices
  const handleFetchGroupDevices = useCallback(async () => {
    await fetchGroupDevices(false);
    try {
      const data = (await deviceIdAPI.getDevicesByGroupId(
        'json',
      )) as unknown as GroupDevice[];
      setGroupDevices(data);
      setView('group');
      setHasFetched(true);
      setGroupOffset(0);
      setGroupDevicesFetched(true);
      setLastUpdated(new Date().toLocaleString());
    } catch (error) {
      console.error('Error fetching group devices:', error);
      setGroupDevices([]);
      setHasFetched(true);
    } finally {
      setIsLoadingGroup(false);
    }
  }, [fetchGroupDevices]);

  useEffect(() => {
    if (detailsRef.current) {
      detailsRef.current.style.maxHeight = hasFetched
        ? `${detailsRef.current.scrollHeight}px`
        : '0';
    }
  }, [hasFetched, sensors, groupDevices]);

  useEffect(() => {
    fetchGroupDevices(true);
  }, [fetchGroupDevices]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        fetchGroupDevices(true);
      },
      5 * 60 * 1000,
    ); //automatically updates every 5 minutes

    return () => clearInterval(interval);
  }, [fetchGroupDevices]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // auto fetch group devices on load, but only if user is allowed
  useEffect(() => {
    if (!email || !isAllowedDomain) return;

    const timer = setTimeout(() => {
      handleFetchGroupDevices();
    }, 300);

    return () => clearTimeout(timer);
  }, [email, isAllowedDomain, handleFetchGroupDevices]);

  if (!email || !isAllowedDomain) {
    return <NotFound />;
  }

  // fetch single device data
  const handleFetchDeviceById = async () => {
    if (!deviceId) {
      return;
    }
    if ((startTime && !endTime) || (!startTime && endTime)) {
      setDeviceError('Both dates or neither must be provided');
      return;
    }
    if (startTime >= endTime && startTime && endTime) {
      setDeviceError('Start date must be before end date');
      return;
    }

    setIsLoadingDevice(true);
    setDeviceError('');
    try {
      let data: DeviceData;
      if (startTime && endTime) {
        data = (await deviceIdAPI.getDeviceDataByDateRange(
          parseInt(deviceId, 10),
          new Date(startTime),
          new Date(endTime),
        )) as DeviceData;
      } else {
        data = (await deviceIdAPI.getDeviceById(
          parseInt(deviceId, 10),
        )) as DeviceData;
      }
      const sensorList: Sensor[] = data.readings.map(
        // eslint-disable-next-line
        (r: any, index: number) => ({
          number: index,
          id: data.id,
          name: data.name,
          status: data.status,
          timeStamp: r.timestamp,
          value: parseFloat(r.value),
          unit: r.unit_name,
          channel: r.channel_name,
        }),
      );
      setSensors(sensorList);
      setView('device');
      setHasFetched(true);
      setSensorOffset(0);
      // eslint-disable-next-line
    } catch (error: any) {
      console.error('Error fetching device data by ID:', error);
      if (error.response && error.response.status === 500) {
        setDeviceError('No results or too large date frame');
      } else {
        setDeviceError('Error fetching device data');
      }
      setSensors([]);
      setView('device');
      setHasFetched(true);
    } finally {
      setIsLoadingDevice(false);
    }
  };
  // table row components for group sensors
  const GroupDevicesTableRows = ({ data }: { data: GroupDevice[] }) => (
    <>
      {data.map((device) => (
        <tr key={device.id} className="sensor-flex-row">
          <td>{device.id}</td>
          <td>{device.name}</td>
          <td>{device.status}</td>
          <td>{device.group_id}</td>
        </tr>
      ))}
    </>
  );

  // Table row components for sensors
  const SensorsTableRows = ({ data }: { data: Sensor[] }) => (
    <>
      {data.map((sensor) => (
        <tr key={`${sensor.id}-${sensor.number}`} className="sensor-flex-row">
          <td>{sensor.number}</td>
          <td>{sensor.id}</td>
          <td>{sensor.name}</td>
          <td>{sensor.status}</td>
          <td>{sensor.timeStamp}</td>
          <td>{sensor.value}</td>
          <td>{sensor.unit}</td>
          <td>{sensor.channel}</td>
        </tr>
      ))}
    </>
  );

  // Paginated sensors
  const PaginatedSensors = ({
    itemsPerPage,
    offset,
    setOffset,
    data,
  }: {
    itemsPerPage: number;
    offset: number;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
    data: Sensor[];
  }) => {
    const endOffset = offset + itemsPerPage;
    const currentItems = data.slice(offset, endOffset);
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const currentPage = Math.floor(offset / itemsPerPage);

    const handlePageClick = (event: { selected: number }) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setOffset(newOffset);
    };

    return (
      <>
        <SensorsTableRows data={currentItems} />
        <tr>
          <td colSpan={8}>
            <div className="pagination-container">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="sensor-paginate"
                activeClassName="selected"
                forcePage={currentPage}
              />
            </div>
          </td>
        </tr>
      </>
    );
  };

  // Paginated group devices
  const PaginatedGroupDevices = ({
    itemsPerPage,
    offset,
    setOffset,
    data,
  }: {
    itemsPerPage: number;
    offset: number;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
    data: GroupDevice[];
  }) => {
    const endOffset = offset + itemsPerPage;
    const currentItems = data.slice(offset, endOffset);
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const currentPage = Math.floor(offset / itemsPerPage);

    const handlePageClick = (event: { selected: number }) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setOffset(newOffset);
    };

    return (
      <>
        <GroupDevicesTableRows data={currentItems} />
        <tr>
          <td colSpan={4}>
            <div className="pagination-container">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName="sensor-paginate"
                activeClassName="selected"
                forcePage={currentPage}
              />
            </div>
          </td>
        </tr>
      </>
    );
  };

  const SensorStatusVisual = ({ data }: { data: GroupDevice[] }) => {
    return (
      <div className="sensor-status-container">
        <div className="sensor-status-grid">
          {data.map((device) => (
            <div key={device.id} className="sensor-status-item">
              <div
                className={`status-dot ${device.status.toLowerCase() === 'active' ? 'active' : 'inactive'}`}
              />
              <div className="sensor-info">
                <span className="sensor-id">{device.id}</span>
                {window.innerWidth > 490 ? (
                  <span className="sensor-name">{device.name}</span>
                ) : null}
                {/*Removes the device name from the sensor status box on smaller devices*/}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const calcInterval = (startTime: string, endTime: string) => {
    const duration =
      new Date(endTime).getTime() - new Date(startTime).getTime();
    //duration > 12 hours
    if (duration > 12 * 3600000 || (!endTime && !startTime)) {
      return 30 * 60 * 1000; //30 minutes
    }
    if (duration > 6 * 3600000) {
      return 20 * 60 * 1000; //20 minutes
    }
    if (duration > 3 * 3600000) {
      return 15 * 60 * 1000; //15 minutes
    }
    if (duration > 3600000) {
      return 10 * 60 * 1000; //10 minutes
    }
    if (duration <= 60000) {
      return 10 * 1000; //10 seconds
    }
    if (duration <= 3600000) {
      return 5 * 60 * 1000; //5 minutes
    }
  };

  // either return an early NotFound or else render the main UI
  if (!email || !isAllowedDomain) {
    return <NotFound />;
  }

  return (
    <Layout>
      {email && (
        <div className="status-container">
          <h4 className="Greeting">
            <strong>Hello, {email}</strong>
          </h4>
          <div className="top-container" style={{ marginTop: '0.5rem' }}>
            <h3 style={{ color: '#070B45' }} className="sensor-overview">
              <strong>Sensor Overview</strong>
            </h3>
            <div className="sensor-status-section">
              <SensorStatusVisual data={groupDevices} />
            </div>
            <div
              style={{
                marginTop: '0.5rem',
                textAlign: 'left',
                color: '#070B45',
                marginBottom: '0.5rem',
              }}
            >
              <strong>Last updated: {lastUpdated}</strong>
            </div>
          </div>
          <h5 className="Instructions">
            <strong>Fetch device data below</strong>
          </h5>
          <div className="search-container">
            <div className="fetch-group-devices-button">
              <button
                onClick={handleFetchGroupDevices}
                disabled={isLoadingGroup}
                className="fetch-group-devices"
              >
                {isLoadingGroup ? (
                  <svg
                    className="spinner"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <circle
                      className="spinner-circle"
                      cx="12"
                      cy="12"
                      r="10"
                      strokeDasharray="31.4 31.4"
                      strokeDashoffset="0"
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  'Fetch Sensor Status'
                )}
              </button>
            </div>
            <div className="or-line">
              <span>
                <strong>OR</strong>
              </span>
            </div>
            <div>
              <label>
                Device ID
                <input
                  type="text"
                  placeholder="Device ID"
                  value={deviceId}
                  onChange={(e) => setDeviceId(e.target.value)}
                />
              </label>
            </div>
            <div className="date-inputs">
              <div>
                <label className="start-date">
                  (Optional) Start Date:
                  <input
                    type="datetime-local"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </label>
              </div>
              <div>
                <label className="end-date">
                  (Optional) End Date:
                  <input
                    type="datetime-local"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className="fetch-id-button">
              <button
                onClick={handleFetchDeviceById}
                disabled={isLoadingDevice}
                className={`fetch-id-button ${isLoadingDevice ? 'active' : ''}`}
              >
                {isLoadingDevice ? (
                  <svg
                    className="spinner"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <circle
                      className="spinner-circle"
                      cx="12"
                      cy="12"
                      r="10"
                      strokeDasharray="31.4 31.4"
                      strokeDashoffset="0"
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  'Fetch Device by ID'
                )}
              </button>
            </div>
          </div>
          {hasFetched && view === 'device' && sensors.length > 0 && (
            <div className="chart-container">
              <div className="channel-selector">
                {/*function for Adding the buttons to change between graphs*/}
                {[
                  'ACC_RMS_X',
                  'ACC_RMS_Y',
                  'ACC_RMS_Z',
                  'VELOCITY_X',
                  'VELOCITY_Y',
                  'VELOCITY_Z',
                ].map((channel) => {
                  const hasData = sensors.some(
                    (s) =>
                      s.channel.trim().toUpperCase() ===
                      channel.trim().toUpperCase(),
                  );
                  return (
                    <button
                      key={channel}
                      onClick={() => setSelectedChannel(channel)}
                      className={`channel-button ${
                        selectedChannel === channel ? 'active' : ''
                      } ${!hasData ? 'disabled' : ''}`}
                      disabled={!hasData}
                    >
                      {channel}
                    </button>
                  );
                })}
              </div>
              <div className="graph">
                {(() => {
                  // returns all the sensors that match the channel thats selected
                  // Deals with any white spaces in the channel names. On sensor 1623, ACC_RMS_Y has white space at the end of it
                  const filteredSensors = sensors.filter(
                    (sensor) =>
                      sensor.channel.trim().toUpperCase() ===
                      selectedChannel.trim().toUpperCase(),
                  );

                  return filteredSensors.length > 0 ? (
                    <>
                      <h4 className="chart-title">
                        <strong>{selectedChannel} Readings</strong>
                      </h4>
                      <LineChart
                        series={[
                          {
                            data: (() => {
                              //Interval for the average value of a sensor to increase performance instead of loading every point
                              const interval =
                                calcInterval(startTime, endTime) ||
                                5 * 60 * 1000;
                              const groupedData: {
                                [key: number]: { sum: number; count: number };
                              } = {};

                              filteredSensors.forEach((sensor) => {
                                const timestamp = new Date(
                                  sensor.timeStamp,
                                ).getTime();
                                const intervalStart =
                                  Math.floor(timestamp / interval) * interval;
                                //Loads the initial value of 0 into the current list
                                if (!groupedData[intervalStart]) {
                                  groupedData[intervalStart] = {
                                    sum: 0,
                                    count: 0,
                                  };
                                }
                                // Adds the value of the sensor to the sum and increases the count
                                groupedData[intervalStart].sum += sensor.value;
                                groupedData[intervalStart].count++;
                              });
                              // Sorts the data into chronological order then finds the average
                              return Object.keys(groupedData)
                                .map(Number)
                                .sort((a, b) => a - b)
                                .map(
                                  (intervalStart) =>
                                    groupedData[intervalStart].sum /
                                    groupedData[intervalStart].count,
                                );
                            })(),
                            color: '#414288',
                          },
                        ]}
                        xAxis={[
                          {
                            data: (() => {
                              const interval =
                                calcInterval(startTime, endTime) ||
                                5 * 60 * 1000;
                              const intervals = new Set<number>();

                              filteredSensors.forEach((sensor) => {
                                const timestamp = new Date(
                                  sensor.timeStamp,
                                ).getTime();
                                intervals.add(
                                  Math.floor(timestamp / interval) * interval,
                                );
                              });

                              return Array.from(intervals)
                                .sort((a, b) => a - b)
                                .map((intervalStart) => intervalStart);
                            })(),
                            label: 'Time',
                            scaleType: 'time',
                            valueFormatter: (
                              value, //formats the time from epoch time to standard time
                            ) =>
                              new Date(value).toLocaleTimeString('en-GB', {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                              }),
                          },
                        ]}
                        yAxis={[
                          {
                            label: filteredSensors[0]?.unit || 'Value',
                            labelStyle: {
                              transform: 'translateX(-5px)',
                              angle: 0,
                              textAnchor: 'end',
                            },
                          },
                        ]}
                        className="line-chart"
                        margin={{
                          left: 70,
                          right: 30,
                          top: 30,
                          bottom: 70,
                        }}
                      />
                    </>
                  ) : null;
                })()}
              </div>
            </div>
          )}
          {(() => {
            // group mode -> donut chart
            if (view === 'group' && groupDevices.length > 0) {
              const total = groupDevices.length;
              const inactive = groupDevices.filter(
                (g) => g.status === 'inactive',
              ).length;
              const active = total - inactive;

              const donutData = {
                labels: ['Active', 'Inactive'],
                datasets: [
                  {
                    label: 'Device Statuses',
                    data: [active, inactive],
                    backgroundColor: ['#4CAF50', '#F44336'],
                  },
                ],
              };

              return (
                <div className="outage-box donut">
                  <h3>Group Outage Overview</h3>
                  <Doughnut
                    data={donutData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: true,
                    }}
                  />
                  <p style={{ marginTop: '0.75rem' }}>
                    <strong>Active:</strong> {active} &nbsp;|&nbsp;
                    <strong>Inactive:</strong> {inactive}
                  </p>
                </div>
              );
            }
            // device mode -> stacked bar chart, bucketed by hr
            else if (view === 'device' && sensors.length > 0) {
              interface HourData {
                time: string;
                activeCount: number;
                inactiveCount: number;
              }
              const hourMap: Record<string, HourData> = {};

              sensors.forEach((s) => {
                const dt = new Date(s.timeStamp);
                const hour = new Date(
                  dt.getFullYear(),
                  dt.getMonth(),
                  dt.getDate(),
                  dt.getHours(),
                ).toISOString();
                if (!hourMap[hour]) {
                  hourMap[hour] = {
                    time: hour,
                    activeCount: 0,
                    inactiveCount: 0,
                  };
                }
                if (s.status === 'inactive') {
                  hourMap[hour].inactiveCount++;
                } else {
                  hourMap[hour].activeCount++;
                }
              });

              const hourData = Object.values(hourMap).sort((a, b) =>
                a.time < b.time ? -1 : 1,
              );

              const totalReadings = sensors.length;
              const inactiveReadings = sensors.filter(
                (s) => s.status === 'inactive',
              ).length;

              const barData = {
                labels: hourData.map((h) => h.time),
                datasets: [
                  {
                    label: 'Active',
                    data: hourData.map((h) => h.activeCount),
                    backgroundColor: '#2196F3',
                    stack: 'combined',
                  },
                  {
                    label: 'Inactive',
                    data: hourData.map((h) => h.inactiveCount),
                    backgroundColor: '#FF9800',
                    stack: 'combined',
                  },
                ],
              };

              const barOptions = {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                  x: {
                    type: 'time' as const,
                    time: {
                      unit: 'hour' as const,
                    },
                    stacked: true,
                    title: {
                      display: true,
                      text: 'Hour',
                    },
                  },
                  y: {
                    stacked: true,
                    title: {
                      display: true,
                      text: 'Reading Count',
                    },
                  },
                },
              };

              return (
                <div className="outage-box">
                  <h3>Device Status Over Time</h3>
                  <Bar data={barData} options={barOptions} />
                  <p style={{ marginTop: '0.75rem' }}>
                    Of <strong>{totalReadings}</strong> total readings,
                    <strong> {inactiveReadings}</strong> were inactive.
                  </p>
                </div>
              );
            }
            return null;
          })()}
          <div
            ref={detailsRef}
            className={`sensor-container ${hasFetched ? 'expanded' : ''}`}
            style={{ marginTop: '2rem' }}
          >
            {hasFetched && view === 'group' && groupDevices.length === 0 && (
              <h3>No Readings Found For This Device</h3>
            )}
            {hasFetched && view === 'device' && sensors.length === 0 && (
              <h3>
                {deviceError
                  ? deviceError
                  : 'No Readings Found For This Device'}
              </h3>
            )}
            {hasFetched && view === 'group' && groupDevices.length > 0 && (
              <>
                <h3 style={{ color: '#070B45' }} className="search-results">
                  <strong>Sensor Status</strong>
                </h3>
                <div className="sensor-table">
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Group ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      <PaginatedGroupDevices
                        itemsPerPage={10}
                        offset={groupOffset}
                        setOffset={setGroupOffset}
                        data={groupDevices}
                      />
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {hasFetched && view === 'device' && sensors.length > 0 && (
              <>
                <h3 className="search-results" style={{ color: '#070B45' }}>
                  <strong>Readings</strong>
                </h3>
                <div className="sensor-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Number</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Timestamp</th>
                        <th>Value</th>
                        <th>Unit</th>
                        <th>Channel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <PaginatedSensors
                        itemsPerPage={10}
                        offset={sensorOffset}
                        setOffset={setSensorOffset}
                        data={sensors}
                      />
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Status;
